import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Typography from "@mui/material/Typography";
import UsersService from "../../services/UsersService";


function DeleteUser({open, handleCloseDialog, refreshList, users})
{
    const handleDelete = () => {
        UsersService.delete(users.user.userUuid)
            .then(r => {
                refreshList();
            }).catch((error) => {
            console.log("Error:", error);
        });

        handleCloseDialog();
    }

    return (
        users &&
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" >{'Are you sure you want to delete user?'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" >{users.user.userFirstName} {users.user.userLastName}</DialogContentText>
                <Typography >{users.user.userEmail}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button onClick={handleDelete}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteUser;