import axios from 'axios';
import { REPORTO_TOKEN, AUTH_USERNAME } from './AuthenticationService';
import {API_URL} from "./Constants";

class UsersService {
    getUsers() {
        console.log('~~~~~~~~~~~ GET Users');
        const token = sessionStorage.getItem(REPORTO_TOKEN);
        return axios.get(`${API_URL}/users`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    save(request) {
        console.log('~~~~~~~~~~~ SAVE Users');
        console.log('request', request);
        const token = sessionStorage.getItem(REPORTO_TOKEN);

        return axios.post(`${API_URL}/users`, request, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    update(userUUID, users) {
        console.log('~~~~~~~~~~~ UPDATE Users');
        console.log('reportUUID', userUUID);
        console.log('users', users);
        const token = sessionStorage.getItem(REPORTO_TOKEN);

        return axios.put(`${API_URL}/users/${userUUID}`, users, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    delete(userUUID) {
        console.log('~~~~~~~~~~~ DELETE User');
        console.log('~~~~~~~reportUUID', userUUID);
        const token = sessionStorage.getItem(REPORTO_TOKEN);

        return axios.delete(`${API_URL}/users/${userUUID}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    getRoles() {
        console.log('~~~~~~~~~~~ GET Roles');
        const token = sessionStorage.getItem(REPORTO_TOKEN);
        return axios.get(`${API_URL}/roles`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    onboard(request) {
        console.log('~~~~~~~~~~~ Onboard Account');
        console.log('request', request);
        const token = sessionStorage.getItem(REPORTO_TOKEN);

        return axios.post(`${API_URL}/onboarding`, request, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
}

export default new UsersService();
