// assets
import { LoginOutlined, ProfileOutlined, LogoutOutlined, UserAddOutlined } from '@ant-design/icons';

// icons
const icons = {
    LoginOutlined,
    ProfileOutlined,
    LogoutOutlined,
    UserAddOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
    id: 'admin',
    title: 'Admin',
    type: 'group',
    children: [
        // {
        //     id: 'onboard',
        //     title: 'Register',
        //     type: 'item',
        //     url: '/register',
        //     icon: icons.ProfileOutlined
        // },
        {
            id: 'onboard',
            title: 'Create Account',
            type: 'item',
            url: '/onboard',
            icon: icons.ProfileOutlined,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: icons.UserAddOutlined,
            breadcrumbs: false
        },
        {
            id: 'login1',
            title: 'Logout',
            type: 'item',
            url: '/login',
            icon: icons.LogoutOutlined
        },
        // {
        //     id: 'poc',
        //     title: 'POC (DEV only)',
        //     type: 'item',
        //     url: '/poc',
        // },
    ]
};

export default pages;
