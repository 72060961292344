import {useLocation, useNavigate} from 'react-router-dom';

// material-ui
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography
} from '@mui/material';

// project import
import React, {useEffect, useRef, useState} from "react";
import AuthWrapper from "./AuthWrapper";
import AnimateButton from "../../components/@extended/AnimateButton";
import * as Yup from "yup";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {Formik} from "formik";
import AuthenticationService from "../../services/AuthenticationService";

// ================================|| RESET PASSWORD ||================================ //

const SetPassword = () => {
    const loginForm = useRef(null);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [uuid, setUuid] = useState('');
    const currentLocation = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(currentLocation.search);
        const uuid = searchParams.get('uuid');

        if (uuid === null) {
            alert('Error! UUID is missing.');
        }

        setUuid(uuid);
    }, [currentLocation.search]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleOk = () => {
        handleDialogClose();
        navigate('/login');  // Redirect to login page
    };

    const handleReset = () => {
        const form = loginForm.current;
        const password = form['password'].value;
        const confirm = form['confirm'].value;

        console.log('password', password);
        console.log('uuid', uuid);

        if (uuid === null) {
            alert('Error! UUID is missing.');
            return;
        }

        const userEmailResetUUID = {};
        userEmailResetUUID.userEmailResetUUID = uuid;
        userEmailResetUUID.userPassword = password;

        AuthenticationService.resetPassword(userEmailResetUUID)
            .then((response) => {
                setDialogOpen(true);  // Open the confirmation dialog
            })
            .catch((error) => {
                console.error('Error! Failed to reset the password:', error);
                alert('Error! Failed to reset the password.');
            });
    };

    return (
        <>
            <AuthWrapper>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{mb: {xs: -0.5, sm: 0.5}}}>
                            <Typography variant="h3">Reset your password</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Formik
                            initialValues={{
                                password: '',
                                confirm: '',
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                password: Yup.string()
                                    .min(8, 'Password must be at least 8 characters')
                                    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
                                    .matches(/[0-9]/, 'Password must contain at least one number')
                                    .max(20)
                                    .required('New password is required'),
                                confirm: Yup.string()
                                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                                    .max(20)
                                    .required('Confirm new password is required')
                            })}
                            onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                                try {
                                    setStatus({success: false});
                                    setSubmitting(false);
                                } catch (err) {
                                    setStatus({success: false});
                                    setErrors({submit: err.message});
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                                <form noValidate onSubmit={handleSubmit} ref={loginForm}>
                                    <Grid container spacing={3}>
                                        {/*PASSWORD*/}
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="password">New password</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    error={Boolean(touched.password && errors.password)}
                                                    id="-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    name="password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                size="large"
                                                            >
                                                                {showPassword ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    placeholder="New password"
                                                />
                                                {touched.password && errors.password && (
                                                    <FormHelperText error id="standard-weight-helper-text-password">
                                                        {errors.password}
                                                    </FormHelperText>
                                                )}
                                                <FormHelperText>
                                                    At least 8 characters including 1 uppercase letter and 1 number
                                                </FormHelperText>
                                            </Stack>
                                        </Grid>

                                        {/*CONFIRM*/}
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="confirm">Confirm new password</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    error={Boolean(touched.confirm && errors.confirm)}
                                                    id="-confirm"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={values.confirm}
                                                    name="confirm"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                size="large"
                                                            >
                                                                {showPassword ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    placeholder="Confirm new password"
                                                />
                                                {touched.confirm && errors.confirm && (
                                                    <FormHelperText error id="standard-weight-helper-text-confirm">
                                                        {errors.confirm}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>

                                        {/*SUBMIT*/}
                                        {errors.submit && (
                                            <Grid item xs={12}>
                                                <FormHelperText error>{errors.submit}</FormHelperText>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <AnimateButton>
                                                <Button
                                                    onClick={handleReset}
                                                    disableElevation
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Reset my password
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </AuthWrapper>

            <Dialog open={dialogOpen}>
                <DialogTitle>Password Reset</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Password has been successfully reset.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOk} color="primary">
                        Continue to Login
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default SetPassword;
