import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Alert, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, InputLabel, OutlinedInput, Stack} from "@mui/material";
import AnimateButton from "../../components/@extended/AnimateButton";
import UsersService from "../../services/UsersService";

const isEmpty = (str) => {
    return (str === undefined || str.trim() === '');
};

function CreateAccount() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');

    // validation
    const [errorFirstName, setErrorFirstName] = useState(false);
    const [errorLastName, setErrorLastName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorCompany, setErrorCompany] = useState(false);
    const [openErrorMessage, setOpenErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // UI
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [message, setMessage] = useState('');
    const handleCloseBackdrop = () => setOpenBackdrop(false);
    const handleOpenBackdrop = () => setOpenBackdrop(true);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => {

    }, []);

    const handleSave = () => {
        let errorFlag = false;
        if (isEmpty(firstName)) {
            setErrorFirstName(true);
            errorFlag = true;
        }
        if (isEmpty(lastName)) {
            setErrorLastName(true);
            errorFlag = true;
        }
        if (isEmpty(email)) {
            setErrorEmail(true);
            errorFlag = true;
        }
        if (isEmpty(company)) {
            setErrorCompany(true);
            errorFlag = true;
        }

        if (errorFlag === true) {
            return;
        }

        const request = {};
        request.userFirstName = firstName;
        request.userLastName = lastName;
        request.userEmail = email;
        request.companyName = company;

        setMessage(`The account for ${firstName} ${lastName}'s company, ${company}, has been successfully onboarded.
        An email confirmation has been sent to ${email}`);

        handleOpenBackdrop();
        UsersService.onboard(request)
            .then(response => {
                handleCloseBackdrop();
                setOpenDialog(true);
                clearFields();
            })
            .catch((error) => {
                console.log("Error onboarding: ", error);
                setErrorMessage('Error creating account ' + error.response.data.message);
                setOpenErrorMessage(true);
                handleCloseBackdrop();
                return;
            });
    }

    const clearFields = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setCompany('');
        setErrorFirstName(false);
        setErrorLastName(false);
        setErrorEmail(false);
        setErrorCompany(false);
        setOpenErrorMessage(false);
        setErrorMessage(false);
    }


    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', m: 8}}>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={openBackdrop}
                    onClick={handleCloseBackdrop}
                ><CircularProgress color="inherit"/></Backdrop>
                {openErrorMessage && <Alert severity="error">{errorMessage}</Alert>}

                <Box sx={{width: '50%', p: 6, mt: 2, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}}>
                    <Box>
                        <Typography variant="h3" sx={{mb: 4}}>Onboard Account</Typography>
                    </Box>

                    <Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="firstname-signup">First Name*</InputLabel>
                                    <OutlinedInput
                                        id="firstname-login"
                                        type="firstname"
                                        name="firstname"
                                        placeholder="Jane"
                                        fullWidth
                                        value={firstName}
                                        onChange={(e) => {
                                            setErrorFirstName(false)
                                            setFirstName(e.target.value)
                                        }}
                                    />
                                    {errorFirstName && <FormHelperText error id="helper-text-firstname-signup"> First Name is required</FormHelperText>}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="lastname-signup">Last Name*</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        id="lastname-signup"
                                        type="lastname"
                                        name="lastname"
                                        placeholder="Doe"
                                        value={lastName}
                                        onChange={(e) => {
                                            setErrorLastName(false)
                                            setLastName(e.target.value)
                                        }}
                                    />
                                    {errorLastName && <FormHelperText error id="helper-text-lastname-signup">Last Name is required</FormHelperText>}

                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="company-signup">Company</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        id="company-signup"
                                        name="company"
                                        placeholder="Reporto Inc."
                                        value={company}
                                        onChange={(e) => {
                                            setErrorCompany(false)
                                            setCompany(e.target.value)
                                        }}
                                    />
                                    {errorCompany && <FormHelperText error id="helper-text-company-signup">Company is required </FormHelperText>}

                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-signup">Email Address*</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        id="email-login"
                                        type="email"
                                        name="email"
                                        placeholder="reporto@company.com"
                                        value={email}
                                        onChange={(e) => {
                                            setErrorEmail(false)
                                            setEmail(e.target.value)
                                        }}
                                    />
                                    {errorEmail && <FormHelperText error id="helper-text-email-signup">Email is required</FormHelperText>}

                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{mt: 5}}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                >
                                    Create Account
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{fontSize: '20px'}}>
                    Account Successfully Onboarded
                </DialogTitle>
                <DialogContent sx={{fontSize: '16px'}}>
                    {message}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>{'Close'}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CreateAccount;
