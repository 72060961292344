// assets
import {
    DashboardOutlined,
    BookOutlined,
    UploadOutlined,
    MailOutlined,
    LayoutOutlined,
    SolutionOutlined,
    ContactsOutlined
} from '@ant-design/icons';

// icons
const icons = {
    DashboardOutlined,
    BookOutlined,
    UploadOutlined,
    MailOutlined,
    LayoutOutlined,
    SolutionOutlined,
    ContactsOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.DashboardOutlined,
            breadcrumbs: false
        },
        {
            id: 'reports',
            title: 'My Reports',
            type: 'item',
            url: '/reports',
            icon: icons.SolutionOutlined,
            breadcrumbs: false
        },
        {
            id: 'report-template',
            title: 'Report Template',
            type: 'item',
            url: '/template',
            icon: icons.LayoutOutlined,
            breadcrumbs: false
        },
        {
            id: 'distribution-list',
            title: 'Distribution List',
            type: 'item',
            url: '/reportdistribution',
            icon: icons.ContactsOutlined,
            breadcrumbs: false
        },
    ]
};

export default dashboard;
