import React, {useEffect, useState} from 'react';
import {Alert, Button, Snackbar, Stack, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import UsersService from "../../services/UsersService";
import Typography from "@mui/material/Typography";
import AddUser from "././AddUser";
import DeleteUser from "./DeleteUser";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

function Users() {
    const [users, setUsers] = useState();
    const [usersList, setUsersList] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [snackbar, setSnackbar] = React.useState(false);
    const handleCloseSnackbar = () => setSnackbar(false);

    const getUsers = () => {
        UsersService.getUsers()
            .then((response) => {
                console.log(response.data);
                if (Object.keys(response.data).length) {
                    console.log('response.data:', response.data);
                    setUsersList(response.data);
                } else {
                    setUsersList([]);
                }
            })
            .catch(() => {
                console.log('ERROR getUsers');
                // navigate('/login');
            });
    }

    useEffect(() => {
        getUsers();
    }, []);

    // dialog handlers
    const handleOpenAddDialog = () => {
        setUsers(null);
        setOpenAddDialog(true);
    };
    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleRefreshList = () => {
        getUsers()
    }

    const handleEdit = (e, row) => {
        e.stopPropagation();

        setUsers(row);
        setOpenAddDialog(true);

    };

    const handleDelete = (e, row) => {
        e.stopPropagation();

        setUsers(row);
        setOpenDeleteDialog(true);
    };

    const columns = [
        {
            field: 'userFirstName',
            headerName: 'User name',
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.user.userFirstName} ${params.row.user.userLastName}`;
            },
        },
        {
            field: 'userEmail', headerName: 'Email', flex: 1,
            valueGetter: (params) => {
                return `${params.row.user.userEmail}`;
            },
        },
        {
            field: 'userRoles', headerName: 'Roles', flex: 2,
            valueGetter: (params) => {
                const roleNames = params.row.userRoles.map(role => role.roleName).join(', ');
                return roleNames;
            },
        },
        {
            field: 'actions', headerName: 'Actions', flex: 0.5, sortable: false, disableColumnMenu: true, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <>

                        <Stack spacing={1} direction="row">
                            <Tooltip title="Edit">
                                <IconButton size="small" onClick={(e) => handleEdit(e, params.row)}>
                                    <EditOutlinedIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton size="small" onClick={(e) => handleDelete(e, params.row)}>
                                    <DeleteOutlinedIcon/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </>
                );
            }
        }
    ];
    const getRowId = (row) => row.user.userId;

    return (
        <>
            <Box>
                <Typography variant="h4" sx={{pb:3,}}>Reporto Users</Typography>
            </Box>
            <Box sx={{mb: 1, textAlign: 'left'}}>
                <Button variant="contained" onClick={handleOpenAddDialog}>Add User</Button>
            </Box>
            <Box sx={{width: '100%', p: 2, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}}>
                <Box sx={{height: 600, width: '100%'}}>
                    <DataGrid
                        disableRowSelectionOnClick
                        rows={usersList}
                        columns={columns}
                        getRowId={getRowId}
                        slots={{toolbar: CustomToolbar,}}
                        // getRowHeight={() => 'auto'}
                    />
                </Box>

                <AddUser open={openAddDialog} handleCloseDialog={handleCloseAddDialog} refreshList={handleRefreshList} users={users}/>
                <DeleteUser open={openDeleteDialog} handleCloseDialog={handleCloseDeleteDialog} refreshList={handleRefreshList} users={users}/>

                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={5000}
                    >
                        <Alert severity="warning" onClose={handleCloseSnackbar}>You have insufficient rights to access this user</Alert>
                    </Snackbar>
                )}
            </Box>
        </>
    );
}

export default Users;