import React, {useState} from 'react';
import DialogGrid from "../components-report/DialogGrid";
import {Button} from "@mui/material";


function PocPage(props) {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleSave = (value) => {
        console.log('parent: ', value);
    }

    return (
        <>
            <Button variant={'contained'} onClick={() => setOpen(true)}>open dialog grid</Button>

            {/*<DialogGrid*/}
            {/*    open={open}*/}
            {/*    handleClose={handleClose}*/}
            {/*    component={null}*/}
            {/*    componentData={null}*/}
            {/*    handleSave={handleSave}*/}
            {/*/>*/}
        </>
    );
}

export default PocPage;