import axios from 'axios';
import {API_URL} from "./Constants";

export const AUTH_USERNAME = 'authenticatedUsername';
export const REPORTO_TOKEN = 'authenticatedUser';

class AuthenticationService {
    executeAuthenticationService(username, password) {
        // console.log('-------------------------- LOGIN', username, password);
        return axios.post(`${API_URL}/login`, {
            authenticationType: 'S',
            userEmail: username,
            userPassword: password
        });
    }

    resetPassword(userEmailResetUUID) {
        // console.log('-------------------------- resetPassword', userEmailResetUUID);
        const token = sessionStorage.getItem(REPORTO_TOKEN);
        return axios.post(`${API_URL}/users/set-password`,
            userEmailResetUUID,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

    registerSuccessfulLogin(username, token) {
        console.log('------------------- registerSuccessfulLogin: SET REPORTO_TOKEN in sessionStorage', token);
        console.log('------------------- registerSuccessfulLogin: SET AUTH_USERNAME in sessionStorage', username);
        sessionStorage.setItem(AUTH_USERNAME, username);
        sessionStorage.setItem(REPORTO_TOKEN, token);
        //this.setupAxiosInterceptors(this.createToken(token))
    }

    createToken(token) {
        // console.log('----------- createToken: token', token);
        return 'Bearer ' + token;
    }

    logout() {
        const token = sessionStorage.getItem(REPORTO_TOKEN);
        axios.post(`${API_URL}/logout`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        sessionStorage.removeItem(AUTH_USERNAME);
        sessionStorage.removeItem(REPORTO_TOKEN);
    }

    isUserLoggedIn() {
        // console.log('----------- isUserLoggedIn: GET AUTH_USERNAME from sessionStorage', sessionStorage.getItem(AUTH_USERNAME));
        let user = sessionStorage.getItem(AUTH_USERNAME);
        // console.log(user)
        if (user === null) return false;
        return true;
    }

    getLoggedInUserName() {
        let user = sessionStorage.getItem(AUTH_USERNAME);
        if (user === null) return '';
        return user;
    }

    setupAxiosInterceptors(token) {
        // console.log('----------- setupAxiosInterceptors: token', token);
        axios.interceptors.request.use((config) => {
            if (this.isUserLoggedIn()) {
                console.log('-----------  config.headers.authorization = token', token);
                config.headers.authorization = token;
            }
            return config;
        });
    }
}

export default new AuthenticationService();
