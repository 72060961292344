import React, {useEffect, useRef, useState} from 'react';
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Snackbar} from '@mui/material';
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import DeleteIcon from "@mui/icons-material/Delete";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

const DialogGrid = ({component, componentData, open, handleClose, handleSave, displayName, description}) => {
    const agGridRef = useRef(null);
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    useEffect(() => {
        // console.log('--> component', component);
        console.log('--> DialogGrid componentData', componentData);

        let chartCategories = componentData.chartCategories;
        let hasChartCategories = componentData.chartCategories.length > 0

        let xCategories = component.xaxisCategories; // columns
        let yCategories = hasChartCategories ? chartCategories : component.yaxisCategories; // row categories

        /** --------------------------------------- 1 */
        xCategories.unshift('category');
        const columnsArray = xCategories.map(element => element.replace(/\s/g, ''));

        let agGridCols = columnsArray.map(column => {
            if (column === "category") {
                return {
                    field: column,
                    editable: true,
                    cellStyle: {
                        color: "black",
                        backgroundColor: "#f3f3f3"
                    }
                };
            } else {
                return {
                    field: column,
                    editable: true
                };
            }
        });

        agGridCols = [...agGridCols, {field: 'Delete', width: 80, checkboxSelection: true},];
        console.log('1. AG setColDefs ', agGridCols);
        setColDefs(agGridCols);

        /** --------------------------------------- 2 */
        const categoryObject = {};
        // Iterate over the categories array starting from index 1
        for (let i = 0; i < columnsArray.length; i++) {
            // Set each category as a key in the object with an initial value of 0
            if (i === 0)
                categoryObject[columnsArray[i]] = "";
            else
                categoryObject[columnsArray[i]] = 0;
        }
        console.log('2. categoryObject', categoryObject);

        /** --------------------------------------- 3 */
        let agGridRows = yCategories
            .filter(row => row !== "null") // Filter out null values
            .map((row, index) => {
                let newObj = {...categoryObject}; // Create a new object based on the template obj
                let keys = Object.keys(newObj);
                // console.log('KEYS!!!', keys);
                for (let i = 0; i < keys.length; i++) {
                    newObj[keys[0]] = row;
                    newObj[keys[1]] = componentData.chartData1[index] ?? 0;
                    newObj[keys[2]] = componentData.chartData2[index] ?? 0;
                    newObj[keys[3]] = componentData.chartData3[index] ?? 0;
                    newObj[keys[4]] = componentData.chartData4[index] ?? 0;
                    newObj[keys[5]] = componentData.chartData5[index] ?? 0;
                    newObj[keys[6]] = componentData.chartData6[index] ?? 0;
                    newObj[keys[7]] = componentData.chartData7[index] ?? 0;
                    newObj[keys[8]] = componentData.chartData8[index] ?? 0;
                    newObj[keys[9]] = componentData.chartData9[index] ?? 0;
                    newObj[keys[10]] = componentData.chartData10[index] ?? 0;
                }
                return newObj;
            });

        console.log('3. AG setRowData', agGridRows);
        setRowData(agGridRows);

    }, [componentData]);

    const handleSaveModal = () => {
        const gridApi = agGridRef.current.api;
        gridApi.stopEditing();

        const gridData = [];
        gridApi.forEachNode(node => {
            gridData.push(node.data);
        });

        console.log('SAVING ag_grid_data', gridData);
        const arrays = [];

        if (gridData.length > 0) {
            const keys = Object.keys(gridData[0]);
            const allCategoriesNotEmpty = gridData.every(item => item[keys[0]].trim() !== "");
            if (!allCategoriesNotEmpty) {
                setOpenAlert(true);
                return;
            }

            keys.forEach(key => {
                const values = gridData.map(item => item[key] ?? 0);
                arrays.push(values);
            });
        }

        console.log('==> saving chart values', arrays);
        handleSave(arrays);
        handleClose();
    };

    const handleAddRow = () => {
        // console.log('------- ADD ROW');
        const columnsArray = component.xaxisCategories.map(element => element.replace(/\s/g, ''));
        const categoryObject = {};
        // Iterate over the categories array starting from index 1
        for (let i = 0; i < columnsArray.length; i++) {
            // Set each category as a key in the object with an initial value of 0
            if (i === 0)
                categoryObject[columnsArray[i]] = "";
            else
                categoryObject[columnsArray[i]] = 0;
        }

        setRowData([...rowData, categoryObject]);
    };

    const handleDeleteRow = () => {
        // console.log('------- DELETE ROW');
        const gridApi = agGridRef.current.api;
        const selectedNodes = gridApi.getSelectedNodes();
        const updatedRows = rowData.filter(row => !selectedNodes.some(selectedNode => selectedNode.data === row));
        setRowData(updatedRows);
    };

    const handleTest = () => {
        const gridApi = agGridRef.current.api;
        const gridData = [];
        gridApi.forEachNode(node => {
            gridData.push(node.data);
        });
        console.log('TEST GRID DATA', gridData, colDefs);
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth maxWidth={'lg'}>

            <DialogTitle id="alert-dialog-title">{displayName}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{mb: 2}}>{description}</DialogContentText>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item><Button color={'primary'} startIcon={<PlaylistAddIcon/>} onClick={handleAddRow}>Add row</Button></Grid>
                    <Grid item><Button color={'error'} startIcon={<DeleteIcon/>} onClick={handleDeleteRow}>Delete selected rows</Button></Grid>
                </Grid>
                <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert variant="filled" severity="error" color="error" onClose={handleCloseAlert}>
                        Category must have a value.
                    </Alert>
                </Snackbar>

                <Box
                    className="ag-theme-quartz"
                    style={{height: 500}}
                >
                    <AgGridReact
                        ref={agGridRef}
                        rowData={rowData}
                        columnDefs={colDefs}
                        rowSelection="multiple"
                        suppressRowClickSelection={"true"}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                {/*<Button onClick={handleTest}>TEST</Button>*/}
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSaveModal}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogGrid;
