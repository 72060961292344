import React, {useEffect, useState} from 'react';
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UsersService from "../../services/UsersService";

const fieldSize = 'medium';

const isEmpty = (str) => {
    return (str === undefined || str.trim() === '');
};


function AddUser({open, handleCloseDialog, refreshList, users}) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [roleIds, setRoleIds] = useState([]);
    const [roles, setRoles] = useState([]);

    // validation
    const [errorFirstName, setErrorFirstName] = useState(false);
    const [errorLastName, setErrorLastName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorRoleIds, setErrorRoleIds] = useState(false);

    // notifications
    const [openErrorMessage, setOpenErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const handleCloseBackdrop = () => setOpenBackdrop(false);
    const handleOpenBackdrop = () => setOpenBackdrop(true);

    const clearFields = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setRoleIds([]);
        setErrorMessage('');

        setErrorFirstName(false);
        setErrorLastName(false);
        setErrorEmail(false);
        setErrorRoleIds(false);
    }

    useEffect(() => {
        console.log('---users: ', users);

        UsersService.getRoles()
            .then((response) => response.data)
            .then((data) => {
                setRoles(data);
            });


        clearFields();
        if (users) {
            setFirstName(users.user.userFirstName);
            setLastName(users.user.userLastName);
            setEmail(users.user.userEmail);
            setRoleIds(users.userRoles ? users.userRoles.map(item => item.roleId) : []);
        }
    }, [open]);

    const handleSaveUsers = () => {
        let errorFlag = false;
        if (isEmpty(firstName)) {
            setErrorFirstName(true);
            errorFlag = true;
        }
        if (isEmpty(lastName)) {
            setErrorLastName(true);
            errorFlag = true;
        }
        if (isEmpty(email)) {
            setErrorEmail(true);
            errorFlag = true;
        }
        if (roleIds.length == 0) {
            setErrorRoleIds(true);
            errorFlag = true;
        }

        if (errorFlag === true) {
            // setOpenAlertError(true);
            return;
        }

        const selectedRoles = roles.filter(obj => roleIds.includes(obj.roleId));

        if (users) {
            const request = {
                user: users.user
            };
            request.user.userFirstName = firstName;
            request.user.userLastName = lastName;
            request.user.userEmail = email;
            request.userRoles = selectedRoles;

            console.log('======> UPDATE reporto user:', request);
            handleOpenBackdrop();
            UsersService.update(request.user.userUuid, request)
                .then(response => {
                    refreshList();
                    handleCloseBackdrop();
                    handleCloseAndRefresh();

                })
                .catch((error) => {
                    console.log("Error updating user: ", error);
                    setErrorMessage('Internal error: ' + error);
                    setOpenErrorMessage(true);
                    handleCloseBackdrop();
                    return;
                });
        } else {
            const request = {
                user: {}
            };
            request.user.userFirstName = firstName;
            request.user.userLastName = lastName;
            request.user.userEmail = email;
            request.userRoles = selectedRoles;


            console.log('======> SAVING reporto user:', request);

            handleOpenBackdrop();
            UsersService.save(request)
                .then(response => {
                    refreshList();
                    handleCloseBackdrop();
                    handleCloseAndRefresh();
                })
                .catch((error) => {
                    console.log("Error saving user: ", error);
                    setErrorMessage('Internal error: ' + error);
                    setOpenErrorMessage(true);
                    handleCloseBackdrop();
                    return;
                });
        }
    }

    const handleCloseAndRefresh = () => {
        clearFields();
        handleCloseDialog();
    }

    // select fields handlers
    const handleChangeUserRole = (event) => {
        const {target: {value}} = event;
        setRoleIds(typeof value === 'string' ? value.split(',') : value);
        setErrorRoleIds(false);
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={openBackdrop}
                onClick={handleCloseBackdrop}
            ><CircularProgress color="inherit"/></Backdrop>

            <DialogTitle><Grid container>
                {/*<PersonOutlineIcon sx={{fontSize: 32, color: 'grey'}}/>*/}
                <Typography>{users ? 'Edit Reporto User' : 'Add Reporto User'}</Typography></Grid></DialogTitle>
            <DialogContent>
                {openErrorMessage && <Alert severity="error">{errorMessage}</Alert>}
                {/*<DialogContentText sx={{...sxGrey18, m: 1}}>Fill in details</DialogContentText>*/}
                <FormControl fullWidth>
                    <Box sx={{flexGrow: 1}}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField fullWidth margin="dense" size={fieldSize} label="First name" value={firstName}
                                           error={errorFirstName}
                                           helperText={errorFirstName ? 'First name is required' : ''}
                                           onChange={(e) => {
                                               setErrorFirstName(false);
                                               setFirstName(e.target.value)
                                           }}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth margin="dense" size={fieldSize} label="Last name" value={lastName}
                                           error={errorLastName}
                                           helperText={errorLastName ? 'Last name is required' : ''}
                                           onChange={(e) => {
                                               setErrorLastName()
                                               setLastName(e.target.value)
                                           }}/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        <TextField fullWidth margin="dense" size={fieldSize} label="Email" value={email}
                                   error={errorEmail}
                                   helperText={errorEmail ? 'Email is required' : ''}
                                   onChange={(e) => {
                                       setErrorEmail(false)
                                       setEmail(e.target.value)
                                   }}/>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        <FormControl sx={{mt: 2}} fullWidth>
                            <InputLabel id="user-function-label">User roles</InputLabel>
                            <Select
                                multiple
                                labelId="user-function-label"
                                id="user-function-select"
                                value={roleIds}
                                onChange={handleChangeUserRole}
                                input={<OutlinedInput label="User function"/>}
                                error={errorRoleIds}
                            >
                                {roles != null && roles.map((r) => (
                                    <MenuItem key={r.roleId} value={r.roleId}>{r.roleName}</MenuItem>
                                ))}
                            </Select>
                            {errorRoleIds && <FormHelperText sx={{color: '#d32f2f'}}>Select a user function</FormHelperText>}
                        </FormControl>
                    </Box>
                    {/*<Box sx={{flexGrow: 1, mt: 2}}>*/}
                    {/*    <Typography>Upload profile photo</Typography>*/}
                    {/*    <TextField type={"file"} fullWidth margin="dense" size={fieldSize}/>*/}
                    {/*</Box>*/}
                </FormControl>
            </DialogContent>
            <DialogActions sx={{m: 2}}>
                <Button variant="white" onClick={handleCloseDialog}>Cancel</Button>
                <Button variant="darkGreen" onClick={handleSaveUsers}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddUser;