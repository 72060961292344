import {lazy} from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Users from "../pages/users/Users";
import PocPage from "../pages/sample/PocPage";
import CreateAccount from "../pages/account/CreateAccount";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));
const Reports = Loadable(lazy(() => import('pages/reports/Reports')));
const ReportDistribution = Loadable(lazy(() => import('pages/report-distribution/ReportDistribution')));
const ReportTemplate = Loadable(lazy(() => import('pages/report-template/ReportTemplate')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout/>,
    children: [
        {
            path: '/',
            element: <DashboardDefault/>
        },
        {
            path: 'color',
            element: <Color/>
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault/>
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage/>
        },
        {
            path: 'shadow',
            element: <Shadow/>
        },
        {
            path: 'typography',
            element: <Typography/>
        },
        {
            path: 'icons/ant',
            element: <AntIcons/>
        },
        {
            path: 'reports',
            element: <Reports/>
        },
        {
            path: 'template',
            element: <ReportTemplate/>
        },
        {
            path: 'reportdistribution',
            element: <ReportDistribution/>
        },
        {
            path: 'users',
            element: <Users/>
        },
        {
            path: 'onboard',
            element: <CreateAccount/>
        },
        {
            path: 'poc',
            element: <PocPage/>
        },
    ]
};

export default MainRoutes;
